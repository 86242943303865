<template>
  <div class="banner">
    <div
      class="bg"
      :style="{
        'background-image': `url(${require(`@/assets/images/banner_${image}.jpg`)})`,
      }"
    >
      <div class="content_wrapper">
        <!-- <h1>{{ title }}</h1> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tep-Banner",
  props: {
    title: {
      type: String,
      required: false,
    },
    image: {
      type: Number,
      required: false,
      default: 1,
    },
  },
};
</script>

<style lang="scss" scoped>
.banner {
  position: relative;
  height: 250px;
  width: 100%;
  overflow: hidden;
  //   box-shadow: 0 5px 5px -5px gray;

  h1 {
    color: #fff;
    margin: 0;
    padding: 100px 0 0 0;
  }

  .bg {
    position: absolute;
    height: 100%;
    width: 100%;
    bottom: 0;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media screen and (max-width: 700px) {
  .banner {
    position: relative;
    height: 200px;
  }
}
</style>
